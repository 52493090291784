import React from "react"
import Layout from "../../components/layout"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ContactSalesForm from "../../components/Organisms/ContactSalesForm/ContactSalesForm"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDatabase, faPlay, faCheckCircle } from "@fortawesome/free-solid-svg-icons"


export default function Sales({ data }) {
    debugger;
    const page = data.allContentfulZContactSales.edges[0].node
    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="heading3">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="heading4">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="linkWhite">{children}</p>
            ),

        },
        renderMark: {},
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const {
        id,
        metaData,
        csHHd,
        csHH2,
        csHTxt1,
        csHTxt2,
        csHTxt3,
        csS1HdRght,
        csS1RtxtRght,
        csS1HdLft,
        csS1RtxtLft,
        csFCb1,
        csFCb2,
        csFTxt,
    } = page

    return (
        <Layout
            IsHeaderImageDark={false}
            navColour={"white"}>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="description" content={metaData.description} />
                <link rel="alternate" hreflang="de" href="https://wunderloop.io/de/contact/sales" />
                <link rel="alternate" hreflang="en" href="https://wunderloop.io/en/email/sales" />
            </head>
            <section className="background-container">
                <div className="sale-container">
                    <div className="row justify-content-center pt-0 pb-5">
                        <h2 className="align-top h2-huge h2-drakgray">
                            {csHHd}
                        </h2>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <ContactSalesForm {...page} />
                        </div>
                        <div className="col-lg-5 pl-4 pt-5">
                            <div className="row">
                                <h3 className="align-top h3-large text-white pl-3">
                                    {csHH2}
                                </h3>
                            </div>
                            <div className="row flex-nowrap align-items-center pl-3 pt-3">
                                <FontAwesomeIcon color="white" className="faSaleCheck mt-1 mr-4" icon={faCheckCircle} />
                                <h4 className="h4-large text-white">
                                    {csHTxt1}
                                </h4>
                            </div>
                            <div className="row flex-nowrap align-items-center pl-3 pt-3">
                                <FontAwesomeIcon color="white" className="faSaleCheck mt-1 mr-4" icon={faCheckCircle} />
                                <h4 className="h4-large text-white">
                                    {csHTxt2}
                                </h4>
                            </div>
                            <div className="row flex-nowrap align-items-center pl-3 pt-3">
                                <FontAwesomeIcon color="white" className="faSaleCheck mt-1 mr-4" icon={faCheckCircle} />
                                <h4 className="h4-large text-white">
                                    {csHTxt3}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-bluePurple">
                <div className="sectionFit-container">
                    <div className="row">
                        <div className="col-lg-1 pb-4">
                            <FontAwesomeIcon className="pl-1 pt-1" size="2x" color="#FFFFFF" icon={faDatabase} />
                        </div>
                        <div className="col-lg-5 pr-5">
                            <div className="row">
                                <h3 className="align-top h3-medium text-white">
                                    {csS1HdLft}
                                </h3>
                            </div>
                            <div className="row pt-2 pr-5 pb-5">
                                <h4 className="align-top h4-large h2-lightgray2">
                                    {documentToReactComponents(csS1RtxtLft.json, options)}
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-1 pb-4">
                            <FontAwesomeIcon className="pl-1 pt-1" size="2x" color="#FFFFFF" icon={faPlay} />
                        </div>
                        <div className="col-lg-5 pr-5">

                            <div className="row">
                                <h3 className="align-top h3-medium text-white">
                                    {csS1HdRght}
                                </h3>
                            </div>
                            <div className="row pt-2 pr-5 pb-5">
                                <h4 className="align-top h4-large h2-lightgray2">
                                    {documentToReactComponents(csS1RtxtRght.json, options)}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout >
    );
}


export const query = graphql`
      query($locale: String!) {
        allContentfulZContactSales(
          filter: {
            node_locale: {eq: $locale}
        }) {
        edges {
        node {
            id
            metaData {
                slug
                title
                description
                keywords {
                    keywords
                }
            }
            csHHd
            csHH2
            csHTxt1
            csHTxt2
            csHTxt3
            csS1HdRght
            csS1RtxtRght {
                json
            }
            csS1HdLft
            csS1RtxtLft {
                json
            }
            csFTitle1
            csFTitle2
            csFTitle3
            csFTitle4
            csFTitle5
            csFTitle6
            csFTitle7
            csFTitle8
            csFPh1
            csFPh2
            csFPh3
            csFPh4
            csFPh5
            csFPh6
            csFPh7
            csFPh8
            csFBtn
            csFCb1 {
                csFCb1
            }
            csFCb2 {
                csFCb2
            }
            csFTxt {
                csFTxt
            }
        }
      }
    }
  }
      `
